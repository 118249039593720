import React from "react";
import { motion } from "framer-motion";
import { Button } from "./ui/button";
import { MessageSquare } from "lucide-react";
import { fadeInUp } from "../utils/animations";

const CommunitySection = () => {
    return (
        <motion.section
            className="text-center mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
            transition={{ duration: 0.5, delay: 0.6 }}
        >
            <h2 className="text-2xl font-bold mb-4 text-white">
                Soon to be Trusted by top Innovative Companies 😎
            </h2>
            <p className="text-gray-200 italic mb-6">
                Join our community and be part of the revolution in app translation!
            </p>
            <div className="flex justify-center space-x-4">
                <a
                    href="https://x.com/locsoapp"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button className="bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-full transition-colors duration-300">
                        <svg
                            className="w-5 h-5 mr-2 inline-block"
                            viewBox="0 0 1200 1227"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                                fill="white"
                            />
                        </svg>
                        Follow on X
                    </Button>
                </a>

                <Button
                onClick={() => alert("Just not yet 🌚👀")}
                className="bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-3 rounded-full transition-colors duration-300"
                >
                    <MessageSquare className="w-5 h-5 mr-2 inline-block" />
                    Join Discord
                </Button>
            </div>
        </motion.section>
    );
};

export default CommunitySection;