import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-indigo-900 text-white py-8 relative z-10">
            <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
                <p>&copy; 2024 Loc.So. All rights reserved.</p>
                <nav className="mt-4 md:mt-0" aria-label="Footer Navigation">
                    <ul className="flex space-x-4">
                        <li>
                            <Link to="/" className="hover:text-indigo-400 transition-colors">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy" className="hover:text-indigo-400 transition-colors">
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link to="/terms-of-service" className="hover:text-indigo-400 transition-colors">
                                Terms of Service
                            </Link>
                        </li>
                        <li>
                            <a href="/contact" className="hover:text-indigo-400 transition-colors"
                               onClick={(e) => {
                                   e.preventDefault();
                                   alert("Drop a mail at support@loc.so, thanks in advance :)");
                               }}>
                                Contact
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;