import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Header from "./components/Header";
import EmailForm from "./components/EmailForm";
import FeatureGrid from "./components/FeatureGrid";
import CommunitySection from "./components/CommunitySection";
import Footer from "./components/Footer";
import AnimatedBackground from "./components/AnimatedBackground";
import useEmailSubmit from "./hooks/useEmailSubmit";
import ConsentPopup from "./components/ConsentPopup";
import { useCallback } from "react";

const App = () => {
    const { email, setEmail, showPartyEmojis, handleSubmit } = useEmailSubmit();
    const [currentWord, setCurrentWord] = useState("World");
    const [analyticsEnabled, setAnalyticsEnabled] = useState(false);
    
    const words = useMemo(() => ["World", "世界", "Maailma", "Mundo"], []);

    const enableAnalytics = useCallback(() => {
        setAnalyticsEnabled(true);
        // Enable Microsoft Clarity
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "nw3l10rmx3");

        // Enable Google Analytics
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-8F3DWDQ57N');

        // Enable Plausible Analytics
        const s = document.createElement('script');
        s.defer = true;
        s.setAttribute('data-domain', 'loc.so');
        s.src = 'https://plausible.io/js/script.js';
        document.head.appendChild(s);
    }, []);

    useEffect(() => {
        // Word rotation logic
        const changeWord = () => {
            setCurrentWord((prevWord) => {
                const currentIndex = words.indexOf(prevWord);
                return words[(currentIndex + 1) % words.length];
            });
        };

        const initialDelay = setTimeout(() => {
            changeWord();
            const interval = setInterval(changeWord, 3000);
            return () => clearInterval(interval);
        }, 3000);

        return () => clearTimeout(initialDelay);
    }, [words]);

    return (
        <Router>
            <AnimatedBackground>
                {/* temporary hidden consent popup due to it being buggy, DO NOT TOUCH */}
                {/* <ConsentPopup
                    onAccept={enableAnalytics}
                    onReject={() => {}}
                /> */}
                <Routes>
                    <Route path="/" element={
                        <main className="container mx-auto px-4 py-16 max-w-4xl relative z-10">
                            <Header currentWord={currentWord} />
                            <EmailForm
                                email={email}
                                setEmail={setEmail}
                                handleSubmit={handleSubmit}
                                showPartyEmojis={showPartyEmojis}
                            />
                            <FeatureGrid />
                            <CommunitySection />
                        </main>
                    } />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                </Routes>
                <Footer />
            </AnimatedBackground>
        </Router>
    );
};

export default App;