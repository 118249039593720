import React from 'react';
import { motion } from 'framer-motion';
import FloatingBackButton from '../components/FloatingBackButton';
import EmailForm from '../components/EmailForm';
import useEmailSubmit from '../hooks/useEmailSubmit';

const PrivacyPolicy = () => {
  const { email, setEmail, showPartyEmojis, handleSubmit } = useEmailSubmit();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8 max-w-4xl"
    >
      <FloatingBackButton />
      <h1 className="text-3xl font-bold mb-6 text-white">Privacy Policy for LocSo Inc.</h1>
      <div className="bg-white/10 backdrop-blur-lg rounded-lg p-6 text-white">
        <p className="mb-4 italic">Last updated: September 3rd, 2024</p>

        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-4">Welcome to LocSo Inc. ("we", "our", "us", or the "Company"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our websites loc.so and app.loc.so (collectively, the "Sites") or use our services (the "Services").</p>
        <p className="mb-4">Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Sites or use our Services.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">2. Information We Collect</h2>
        <p className="mb-4">We collect personal information that you voluntarily provide to us when you use our Sites or Services. This information may include, but is not limited to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Social Media Profile information (e.g., from Connect with Facebook, Sign In With Twitter)</li>
          <li>Payment information (when you pay for our products/services)</li>
          <li>Other information you choose to provide</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">3. How We Use Your Information</h2>
        <p className="mb-4">We use your information for various purposes, including but not limited to:</p>
        <ol className="list-decimal list-inside mb-4">
          <li>Providing, maintaining, and improving our Services</li>
          <li>Notifying you about changes to our Services</li>
          <li>Allowing you to participate in interactive features of our Services</li>
          <li>Providing customer support</li>
          <li>Gathering analysis or valuable information to improve our Services</li>
          <li>Monitoring the usage of our Services</li>
          <li>Detecting, preventing, and addressing technical issues</li>
          <li>Sending you transactional and promotional emails</li>
          <li>Processing payments for our products/services</li>
        </ol>

        <h2 className="text-2xl font-semibold mt-6 mb-4">4. How We Share Your Information</h2>
        <p className="mb-4">We may share your personal information in the following situations:</p>
        <ol className="list-decimal list-inside mb-4">
          <li>With service providers: We may share your information with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to perform these tasks.</li>
          <li>For business transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
          <li>With your consent: We may disclose your personal information for any other purpose with your explicit consent.</li>
        </ol>

        <h2 className="text-2xl font-semibold mt-6 mb-4">5. Third-Party Services</h2>
        <ol className="list-decimal list-inside mb-4">
          <li>Microsoft Clarity: We use Microsoft Clarity to track user behavior on our Sites. This may include recording your interactions with our Sites to help us improve user experience. For more information on how Microsoft Clarity handles user data, please refer to their Privacy Policy.</li>
          <li>Microsoft Azure: We use Microsoft Azure for hosting our Services. For more information on how Microsoft Azure handles user data, please refer to their Privacy Policy.</li>
          <li>Cloudflare: We use Cloudflare for DNS, caching, and may use Cloudflare Captcha during periods of high load. For more information on how Cloudflare handles user data, please refer to their Privacy Policy.</li>
          <li>Remarketing Services: We use remarketing services to advertise our business on third-party websites after you have visited our Sites. We and our third-party vendors use cookies to inform, optimize, and serve ads based on your past visits to our Sites. We do not display ads on our own Sites.</li>
          <li>Payment Processing: When you make a purchase, we process payments through third-party payment processors. We do not store your full payment information on our servers.</li>
        </ol>

        <h2 className="text-2xl font-semibold mt-6 mb-4">6. Data Retention</h2>
        <p className="mb-4">We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">7. Data Security</h2>
        <p className="mb-4">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">8. Your Data Protection Rights</h2>
        <p className="mb-4">Depending on your location, you may have certain data protection rights. These may include rights under GDPR, CCPA, CPRA, CalOPPA, LGPD, and PIPEDA. To exercise any of these rights, please contact us using the information provided in the "Contact Us" section.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">9. Children's Privacy</h2>
        <p className="mb-4">Our Sites are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">10. Changes to This Privacy Policy</h2>
        <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">11. Contact Us</h2>
        <p className="mb-4">If you have any questions about this Privacy Policy, please contact us:</p>
        <ul className="list-disc list-inside mb-4">
          <li>By email: privacy@loc.so</li>
          <li>By mail: 447 Broadway, 2nd Floor Suite #2724, New York, New York 10013, United States</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">12. Governing Law</h2>
        <p className="mb-4">This Privacy Policy and your use of our Sites and Services are governed by and construed in accordance with the laws of the State of Delaware, United States, without regard to its conflict of law principles.</p>
      </div>

      <div className="mt-8">
        <EmailForm
          email={email}
          setEmail={setEmail}
          handleSubmit={handleSubmit}
          showPartyEmojis={showPartyEmojis}
        />
      </div>

      <br/><br/><br/>
    </motion.div>
  );
};

export default PrivacyPolicy;