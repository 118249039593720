import { useState } from 'react';

const useEmailSubmit = () => {
  const [email, setEmail] = useState("");
  const [showPartyEmojis, setShowPartyEmojis] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      console.error("Email is required");
      return;
    }
    try {
      const response = await fetch("https://app.loc.so/joinEarlyList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.message);
        setEmail("");
        setShowPartyEmojis(true);
        setTimeout(() => setShowPartyEmojis(false), 3000);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return { email, setEmail, showPartyEmojis, handleSubmit };
};

export default useEmailSubmit;