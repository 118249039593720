import React from "react";
import { Card, CardContent } from "./ui/card";

const FeatureCard = ({ icon: Icon, title, description }) => {
    return (
        <Card className="bg-white/10 border-white/20 backdrop-blur-lg hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-2 hover:shadow-xl">
            <CardContent className="flex items-center p-6 relative overflow-hidden group" style={{ paddingTop: "1.5rem" }}>
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-indigo-500 opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
                <Icon className="w-12 h-12 text-white mr-4 group-hover:scale-110 transition-transform duration-300" />
                <div className="select-text">
                    <h3 className="text-lg font-semibold text-yellow-300 transition-colors duration-300">{title}</h3>
                    <p className="text-gray-200 group-hover:text-white transition-colors duration-300">{description}</p>
                </div>
            </CardContent>
        </Card>
    );
};

export default FeatureCard;