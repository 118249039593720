import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wordAnimation } from "../utils/animations";

const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
};

const Header = ({ currentWord }) => {
    return (
        <motion.header
            className="text-center mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
            transition={{ duration: 0.5 }}
        >
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-4 drop-shadow-lg flex justify-center items-center">
                <span>LocSo: Speak to the</span>
                <span className="inline-block w-[260px] overflow-hidden ml-3 text-left">
          <AnimatePresence mode="popLayout">
            <motion.span
                key={currentWord}
                variants={wordAnimation}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                    y: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.5 },
                }}
                className="inline-block"
            >
              {currentWord}
            </motion.span>
          </AnimatePresence>
        </span>
            </h1>
            <p className="text-xl md:text-2xl text-gray-100 mb-8">
                <span className="text-yellow-300">Loc</span>alization,  
                <span className="text-yellow-300"> So</span> Easy <br/>Instantly translate your app or website into multiple languages<br/>Launch globally, effortlessly
            </p>
        </motion.header>
    );
};

export default Header;