import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const AnimatedBackground = ({ children }) => {
    const backgroundControls = useAnimation();

    useEffect(() => {
        const animateBackground = async () => {
            while (true) {
                await backgroundControls.start({
                    background: [
                        "linear-gradient(45deg, #ff69b4, #9370db)",
                        "linear-gradient(45deg, #00bfff, #9370db)",
                        "linear-gradient(45deg, #00bfff, #ff69b4)",
                        "linear-gradient(45deg, #ff69b4, #9370db)",
                    ],
                    transition: {
                        duration: 100,
                        ease: "linear",
                        repeat: Infinity,
                    },
                });
            }
        };
        animateBackground();
    }, [backgroundControls]);

    return (
        <motion.div
            className="min-h-screen flex flex-col justify-between overflow-hidden"
            animate={backgroundControls}
        >
            <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9IjAuMSI+PC9yZWN0Pgo8L3N2Zz4=')] opacity-20"></div>
            {children}
        </motion.div>
    );
};

export default AnimatedBackground;