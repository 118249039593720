import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { fadeInUp } from "../utils/animations";
import PartyEmoji from "./emojis/PartyEmoji";
import FireEmoji from "./emojis/FireEmoji";
import FallingWord from "./FallingWord";

const thanksWords = [
    {"language": "Mandarin", "translation": "谢谢！"},
    {"language": "Spanish", "translation": "¡Gracias!"},
    {"language": "English", "translation": "Thanks!"},
    {"language": "Hindi", "translation": "धन्यवाद!"},
    {"language": "Ukrainian", "translation": "Дякую!"},
    {"language": "Arabic", "translation": "شكرا!"},
    {"language": "Bengali", "translation": "ধন্যবাদ!"},
    {"language": "Portuguese", "translation": "Obrigado!"},
    {"language": "Russian", "translation": "Спасибо!"},
    {"language": "Japanese", "translation": "ありがとう！"},
    {"language": "Punjabi", "translation": "ਧੰਨਵਾਦ!"},
    {"language": "German", "translation": "Danke!"},
    {"language": "Javanese", "translation": "Matur nuwun!"},
    {"language": "Korean", "translation": "감사합니다!"},
    {"language": "French", "translation": "Merci!"},
    {"language": "Telugu", "translation": "ధన్యవాదాలు!"},
    {"language": "Marathi", "translation": "धन्यवाद!"},
    {"language": "Turkish", "translation": "Teşekkürler!"},
    {"language": "Tamil", "translation": "நன்றி!"},
    {"language": "Urdu", "translation": "شکریہ!"},
    {"language": "Italian", "translation": "Grazie!"},
    {"language": "Vietnamese", "translation": "Cảm ơn!"},
    {"language": "Polish", "translation": "Dziękuję!"},
    {"language": "Romanian", "translation": "Mulțumesc!"},
    {"language": "Dutch", "translation": "Dank je!"},
    {"language": "Greek", "translation": "Ευχαριστώ!"},
    {"language": "Czech", "translation": "Děkuji!"},
    {"language": "Swedish", "translation": "Tack!"},
    {"language": "Hungarian", "translation": "Köszönöm!"},
    {"language": "Finnish", "translation": "Kiitos!"},
    {"language": "Thai", "translation": "ขอบคุณ!"},
    {"language": "Hebrew", "translation": "תודה!"},
    {"language": "Norwegian", "translation": "Takk!"},
    {"language": "Danish", "translation": "Tak!"},
    {"language": "Malay", "translation": "Terima kasih!"},
    {"language": "Indonesian", "translation": "Terima kasih!"},
    {"language": "Filipino", "translation": "Salamat!"},
    {"language": "Swahili", "translation": "Asante!"},
    {"language": "Amharic", "translation": "አመሰግናለሁ!"},
    {"language": "Hausa", "translation": "Na gode!"},
    {"language": "Yoruba", "translation": "E dupe!"},
    {"language": "Zulu", "translation": "Ngiyabonga!"},
    {"language": "Xhosa", "translation": "Enkosi!"},
    {"language": "Afrikaans", "translation": "Dankie!"},
    {"language": "Sesotho", "translation": "Kea leboha!"},
    {"language": "Shona", "translation": "Ndatenda!"},
    {"language": "Kinyarwanda", "translation": "Urakoze!"},
    {"language": "Somali", "translation": "Mahadsanid!"},
    {"language": "Tigrinya", "translation": "የቐንየለይ!"},
    {"language": "Oromo", "translation": "Galatoomi!"},
    {"language": "Burmese", "translation": "ကျေးဇူးတင်ပါတယ်!"},
    {"language": "Khmer", "translation": "អរគុណ!"},
    {"language": "Lao", "translation": "ຂອບໃຈ!"},
    {"language": "Sinhala", "translation": "ස්තුතියි!"},
    {"language": "Kazakh", "translation": "Рақмет!"},
    {"language": "Uzbek", "translation": "Rahmat!"},
    {"language": "Pashto", "translation": "مننه!"},
    {"language": "Dari", "translation": "تشکر!"},
    {"language": "Tajik", "translation": "Ташаккур!"},
    {"language": "Kurdish", "translation": "Spas!"},
    {"language": "Azerbaijani", "translation": "Təşəkkür edirəm!"},
    {"language": "Turkmen", "translation": "Sag boluň!"},
    {"language": "Georgian", "translation": "გმადლობთ!"},
    {"language": "Armenian", "translation": "Շնորհակալություն!"},
    {"language": "Mongolian", "translation": "Баярлалаа!"},
    {"language": "Tongan", "translation": "Mālō 'aupito!"},
    {"language": "Samoan", "translation": "Fa'afetai!"},
    {"language": "Fijian", "translation": "Vinaka!"},
    {"language": "Maori", "translation": "Nga mihi!"},
    {"language": "Haitian Creole", "translation": "Mèsi!"},
    {"language": "Quechua", "translation": "Sulpayki!"},
    {"language": "Guarani", "translation": "Aguyje!"},
    {"language": "Nahuatl", "translation": "Tlazohcamati!"},
    {"language": "Mayan", "translation": "Yuum bo'otik!"},
    {"language": "Inuktitut", "translation": "Nakurmik!"},
    {"language": "Icelandic", "translation": "Takk!"},
    {"language": "Basque", "translation": "Eskerrik asko!"},
    {"language": "Galician", "translation": "Grazas!"},
    {"language": "Catalan", "translation": "Gràcies!"},
    {"language": "Welsh", "translation": "Diolch!"},
    {"language": "Irish", "translation": "Go raibh maith agat!"},
    {"language": "Scottish Gaelic", "translation": "Tapadh leat!"},
    {"language": "Breton", "translation": "Trugarez!"},
    {"language": "Cornish", "translation": "Meur ras!"},
    {"language": "Manx", "translation": "Gura mie ayd!"},
    {"language": "Maltese", "translation": "Grazzi!"},
    {"language": "Luxembourgish", "translation": "Merci!"},
    {"language": "Slovak", "translation": "Ďakujem!"},
    {"language": "Slovenian", "translation": "Hvala!"},
    {"language": "Serbian", "translation": "Хвала!"},
    {"language": "Croatian", "translation": "Hvala!"},
    {"language": "Bosnian", "translation": "Hvala!"},
    {"language": "Macedonian", "translation": "Благодарам!"},
    {"language": "Albanian", "translation": "Faleminderit!"},
    {"language": "Bulgarian", "translation": "Благодаря!"},
    {"language": "Belarusian", "translation": "Дзякуй!"},
    {"language": "Latvian", "translation": "Paldies!"},
    {"language": "Lithuanian", "translation": "Ačiū!"},
    {"language": "Estonian", "translation": "Aitäh!"},
    {"language": "Montenegrin", "translation": "Hvala!"},
    {"language": "Galician", "translation": "Grazas!"},
    {"language": "Corsican", "translation": "Grazie!"},
    {"language": "Sardinian", "translation": "Grazie!"},
    {"language": "Asturian", "translation": "Gracies!"},
    {"language": "Aragonese", "translation": "Grasias!"},
    {"language": "Ladin", "translation": "Grazie!"},
    {"language": "Friulian", "translation": "Mandi!"},
    {"language": "Romani", "translation": "Merci!"},
    {"language": "Western Frisian", "translation": "Tanke!"},
    {"language": "Limburgish", "translation": "Danke!"},
    {"language": "Low German", "translation": "Dankie!"},
    {"language": "Silesian", "translation": "Dziękuja!"},
    {"language": "Kashubian", "translation": "Dziękùje!"},
    {"language": "Võro", "translation": "Tänu!"},
    {"language": "Livonian", "translation": "At!"}
]

const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


const useElementResize = (initialRef = null) => {
    const [ref, setRef] = useState(initialRef);
    const [size, setSize] = useState({ width: 0, height: 0 });

    const updateSize = useCallback(() => {
        if (ref) {
            const { offsetWidth, offsetHeight } = ref;
            setSize({ 
                width: offsetWidth || window.innerWidth, 
                height: offsetHeight || window.innerHeight 
            });
        } else {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        }
    }, [ref]);

    useEffect(() => {
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [ref, updateSize]);

    return [setRef, size];
};

const EmailForm = ({ email, setEmail, handleSubmit, showPartyEmojis }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [partyEmojis, setPartyEmojis] = useState([]);
    const [fireEmojis, setFireEmojis] = useState([]);
    const [fallingWords, setFallingWords] = useState([]);
    const [setMainRef, { width: mainWidth, height: mainHeight }] = useElementResize();
    const inputRef = useRef(null);

    useEffect(() => {
        const mainElement = document.querySelector('main');
        if (mainElement) {
            setMainRef(mainElement);
        }
    }, [setMainRef]);

    useEffect(() => {
        if (showPartyEmojis && mainWidth > 0) {
            const newEmojis = Array.from({ length: 30 }, (_, i) => ({
                id: i,
                x: Math.random() * mainWidth,
                y: Math.random() * -window.innerHeight,
            }));
            setPartyEmojis(newEmojis);
            
            const TOTAL_WORDS_FAILING_TIME = 45000;
            // Set falling words after 5 seconds
            setTimeout(() => {
                const newWords = thanksWords.map((word, index) => ({
                    id: `word-${index}`,
                    word: word.translation,
                    x: Math.random() * mainWidth,
                    delay: 45 / thanksWords.length * (index + 1)
                }));
                setFallingWords(newWords);
            }, 5000);

            const timerPartyEmojis = setTimeout(() => {
                setPartyEmojis([]);
            }, 20000);

            const timerFallingWords = setTimeout(() => {
                setFallingWords([]);
            }, TOTAL_WORDS_FAILING_TIME);

            return () => {
                clearTimeout(timerPartyEmojis);
                clearTimeout(timerFallingWords);
            };
        }
    }, [showPartyEmojis, mainWidth]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await handleSubmit(e);
            // Trigger additional party emojis on form submission
            if (mainWidth > 0) {
                const newEmojis = Array.from({ length: 15 }, (_, i) => ({
                    id: `submit-${i}`,
                    x: Math.random() * mainWidth,
                    y: -50,
                }));
                setPartyEmojis(prev => [...prev, ...newEmojis]);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            // Handle error (e.g., show error message to user)
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
        if (!inputRef.current) return;
    
        const inputRect = inputRef.current.getBoundingClientRect();

        const newFireEmojis = Array.from({ length: 3 }, () => ({
            id: Date.now() + Math.random(),
            x: Math.random() * mainWidth - 20,
            y: inputRect.top - mainHeight * 0.25 - Math.random() * 50,
        }));

        setFireEmojis((prev) => [...prev, ...newFireEmojis]);

        setTimeout(() => {
            setFireEmojis((prev) => prev.filter((emoji) => !newFireEmojis.some(newEmoji => newEmoji.id === emoji.id)));
        }, 5000);
    };

    return (
        <motion.div
            className="relative"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
            transition={{ duration: 0.5, delay: 0.2 }}
        >
            <form
                onSubmit={onSubmit}
                className="flex flex-col md:flex-row gap-4 mb-16 max-w-xl mx-auto"
            >
                <Input
                    ref={inputRef}
                    type="email"
                    placeholder="Be first and get exclusive offer!"
                    value={email}
                    onChange={handleInputChange}
                    className="flex-grow bg-white/10 placeholder:!text-gray-50 text-gray-50 border-white/20 text-[16px] py-6"
                    required
                />
                <Button
                    type="submit"
                    className={`bg-white text-indigo-600 hover:bg-indigo-100 px-6 h-[50px] w-full md:w-auto text-sm md:text-base whitespace-nowrap transition-all duration-300 ${
                        isLoading || !isValidEmail(email)
                            ? 'opacity-50 cursor-not-allowed'
                            : 'animate-pulse-fire'
                    }`}
                    disabled={isLoading || !isValidEmail(email)}
                >
                    {isLoading ? "Joining..." : "Join Early Access List"}
                </Button>
            </form>
            <AnimatePresence>
                {partyEmojis.map((emoji) => (
                    <PartyEmoji key={emoji.id} x={emoji.x} y={emoji.y} />
                ))}
                {fireEmojis.map((emoji) => (
                    <FireEmoji key={emoji.id} x={emoji.x} y={emoji.y} />
                ))}
                {fallingWords.map((word) => (
                    <FallingWord key={word.id} word={word.word} x={word.x} delay={word.delay} />
                ))}
            </AnimatePresence>
        </motion.div>
    );
};

export default EmailForm;