import React from 'react';
import { motion } from 'framer-motion';

interface FireEmojiProps {
    x: number;
    y: number;
}

const FireEmoji: React.FC<FireEmojiProps> = ({ x, y }) => (
    <motion.div
        className="absolute text-2xl pointer-events-none"
        initial={{ x, y }}
        animate={{
            x: [x, x + 25, x, x - 25, x],
            y: [y, y - 25, y - 50, y - 25, y],
        }}
        transition={{
            duration: 5,
            ease: "linear",
            times: [0, 0.25, 0.5, 0.75, 1],
            repeat: Infinity,
        }}
    >
        🔥
    </motion.div>
);

export default FireEmoji;
