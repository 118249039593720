import React from 'react';
import { motion } from 'framer-motion';

const FallingWord = ({ word, x, delay }) => {
    return (
        <motion.div
            className="absolute text-xl pointer-events-none text-white font-bold"
            initial={{ opacity: 0, y: -50, x }}
            animate={{
                opacity: [0, 1, 1, 0],
                y: window.innerHeight,
                transition: {
                    duration: 8,
                    delay,
                    times: [0, 0.1, 0.9, 1],
                    ease: "linear"
                }
            }}
        >
            {word}
        </motion.div>
    );
};

export default FallingWord;