import React from 'react';
import { motion } from 'framer-motion';

const PartyEmoji = ({ x, y }) => {
    const duration = 10 + Math.random() * 5;
    const rotations = Math.floor(Math.random() * 3) + 1;

    return (
        <motion.div
            className="absolute text-3xl pointer-events-none"
            initial={{ opacity: 0, scale: 0, x, y }}
            animate={{ 
                opacity: [0, 1, 1, 0],
                scale: [0, 1, 1, 0],
                y: window.innerHeight,
                rotate: 360 * rotations,
                transition: { 
                    duration: duration,
                    ease: "linear",
                    times: [0, 0.1, 0.9, 1],
                }
            }}
        >
            🎉
        </motion.div>
    );
};

export default PartyEmoji;
