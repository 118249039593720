import {
    Globe,
    Zap,
    Users,
    Lock,
    BadgeDollarSign,
    HeartHandshake,
} from "lucide-react";

export const features = [
    {
        icon: Globe,
        title: "Global Reach",
        description: "Expand your user base across languages and cultures",
    },
    {
        icon: Zap,
        title: "Lightning Fast",
        description: "Translate your entire app or website in minutes, not months",
    },
    {
        icon: Users,
        title: "User Centric",
        description: "Maintain a seamless user experience across all languages",
    },
    {
        icon: Lock,
        title: "Secure & Private",
        description: "You decide on what parts of content to make public",
    },
    {
        icon: BadgeDollarSign,
        title: "Effective & Cheap",
        description: "You don't need team of translators, at worst 1h of freelancer will be enough",
    },
    {
        icon: HeartHandshake,
        title: "Drives Community",
        description: "Got strong bond with your community? Validate and improve translations together!",
    },
];