import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FloatingBackButton = ({ className = '' }) => {
    const [isHovered, setIsHovered] = useState(false);
    const baseClasses = 'fixed left-4 top-1/2 transform -translate-y-1/2 bg-indigo-600 bg-opacity-50 text-white rounded-full p-4 flex flex-col items-center justify-center shadow-lg hover:bg-opacity-100 transition-all duration-300';

    return (
        <Link 
            to="/" 
            className={`${baseClasses} ${className}`.trim()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={`transition-all duration-300 ${isHovered ? 'h-12' : 'h-6'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
                <span className={`mt-1 text-sm transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
                    Back
                </span>
            </div>
        </Link>
    );
};

export default FloatingBackButton;
