import React from 'react';
import { motion } from 'framer-motion';
import EmailForm from '../components/EmailForm';
import useEmailSubmit from '../hooks/useEmailSubmit';
import FloatingBackButton from '../components/FloatingBackButton';

const TermsOfService = () => {
  const { email, setEmail, showPartyEmojis, handleSubmit } = useEmailSubmit();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8 max-w-4xl"
    >
      <FloatingBackButton />
      <h1 className="text-3xl font-bold mb-6 text-white">Terms of Service for LocSo Inc.</h1>
      <div className="bg-white/10 backdrop-blur-lg rounded-lg p-6 text-white">
        <p className="mb-4 italic">Last updated: September 3rd, 2024</p>

        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-4">Welcome to LocSo Inc. ("we", "our", "us", or the "Company"). These Terms of Service ("Terms") govern your use of our websites loc.so and app.loc.so (collectively, the "Sites") and the services we provide (the "Services").</p>
        <p className="mb-4">By accessing or using our Sites and Services, you agree to be bound by these Terms. If you disagree with any part of the Terms, you must not access the Sites or use our Services.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">2. Services</h2>
        <p className="mb-4">LocSo Inc. provides localization services for websites, applications, and software for other SaaS companies. Our Services include, but are not limited to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Processing and translating .json, .xml, or other formatted files, typically containing key-value pairs, with values for the initial language (usually English)</li>
          <li>Offering translation of entire software systems by connecting client repositories from GitHub and creating Pull Requests to enable the use of i18n in their projects</li>
          <li>Providing manual work from LocSo employees, when necessary</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">3. User Accounts</h2>
        <p className="mb-4">3.1. You may be required to create an account to access certain features of our Services. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
        <p className="mb-4">3.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">4. Intellectual Property</h2>
        <p className="mb-4">4.1. All intellectual property generated through our Services, including translations, code changes, and other related content, is fully provided to our clients for their use.</p>
        <p className="mb-4">4.2. By using our Services, you acknowledge and agree that LocSo Inc. retains all rights, title, and interest in and to the Services, including all related intellectual property rights. This includes, but is not limited to, the technology and software used to provide the Services.</p>
        <p className="mb-4">4.3. You grant LocSo Inc. a non-exclusive, worldwide, royalty-free license to use, reproduce, and store any content you provide to us for the purpose of providing and improving our Services.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">5. Prohibited Uses</h2>
        <p className="mb-4">You may not use our Sites or Services:</p>
        <ol className="list-decimal list-inside mb-4">
          <li>For any illegal or unauthorized purpose.</li>
          <li>To violate any laws in your jurisdiction (including but not limited to copyright laws).</li>
          <li>To impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
          <li>To interfere with or disrupt the Services or servers or networks connected to the Services.</li>
          <li>To attempt to gain unauthorized access to any portion of the Services or any other systems or networks connected to the Services.</li>
        </ol>
        <p className="mb-4">You agree to comply with all applicable laws and regulations when using our Services.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">6. Billing and Refunds</h2>
        <p className="mb-4">6.1. Refund Policy: We provide a refund policy for 7 days from the date of purchase. This policy does not apply to the translation of entire applications, which is made upon request and is non-refundable if the work is performed properly, as advertised.</p>
        <p className="mb-4">6.2. Billing:</p>
        <ul className="list-disc list-inside mb-4">
          <li>You agree to pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable.</li>
          <li>The Company reserves the right to modify its fees and charges and to introduce new charges at any time, upon at least 30 days prior notice to you, which may be sent by email or posted on the Sites.</li>
          <li>All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4">7. Limitation of Liability</h2>
        <p className="mb-4">7.1. To the maximum extent permitted by law, in no event shall LocSo Inc., its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:</p>
        <ol className="list-decimal list-inside mb-4">
          <li>your access to or use of or inability to access or use the Services;</li>
          <li>any conduct or content of any third party on the Services;</li>
          <li>any content obtained from the Services; and</li>
          <li>unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage.</li>
        </ol>
        <p className="mb-4">7.2. In no event shall our total liability to you for all damages, losses, and causes of action exceed the amount paid by you, if any, for accessing our Services during the 12 months prior to bringing the claim.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">8. Indemnification</h2>
        <p className="mb-4">You agree to defend, indemnify and hold harmless LocSo Inc. and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Services, by you or any person using your account and password, or b) a breach of these Terms.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">9. Termination</h2>
        <p className="mb-4">9.1. We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
        <p className="mb-4">9.2. Upon termination, your right to use the Services will immediately cease. If you wish to terminate your account, you may simply discontinue using the Services or contact our support email to request account deletion.</p>
        <p className="mb-4">9.3. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">10. Changes to the Terms</h2>
        <p className="mb-4">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">11. Governing Law</h2>
        <p className="mb-4">These Terms shall be governed and construed in accordance with the laws of the State of Delaware, United States, without regard to its conflict of law provisions.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">12. Dispute Resolution</h2>
        <p className="mb-4">Any dispute arising out of or relating to these Terms, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">13. Severability</h2>
        <p className="mb-4">If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">14. Entire Agreement</h2>
        <p className="mb-4">These Terms constitute the entire agreement between us regarding our Services and supersede and replace any prior agreements we might have had between us regarding the Services.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">15. Contact Us</h2>
        <p className="mb-4">If you have any questions about these Terms, please contact us:</p>
        <ul className="list-disc list-inside mb-4">
          <li>By email: support@loc.so</li>
          <li>By mail: 447 Broadway, 2nd Floor Suite #2724, New York, New York 10013, United States</li>
        </ul>
      </div>

      <div className="mt-8">
        <EmailForm
          email={email}
          setEmail={setEmail}
          handleSubmit={handleSubmit}
          showPartyEmojis={showPartyEmojis}
        />
      </div>

      <br/><br/><br/>
    </motion.div>
  );
};

export default TermsOfService;