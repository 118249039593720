import React from "react";
import FeatureCard from "./FeatureCard";
import { features } from "../utils/constants";

const FeatureGrid = () => {
    return (
        <div className="grid md:grid-cols-2 gap-8 mb-16">
            {features.map((item, index) => (
                <FeatureCard key={index} {...item} />
            ))}
        </div>
    );
};

export default FeatureGrid;